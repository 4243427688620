import React, { useEffect, useState } from 'react';
import artBoardImage from 'assets/ArtBoard.svg';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useToken } from 'lib/hooks';
import { TablePaginationConfig, Tag, Tooltip, message } from 'antd';

import { clickToCopy } from 'lib/helpers/clickToCopy';
import EmptyListPlaceholder from 'components/EmptyListPlaceholder';
import CreatePACModal from 'components/Modal/CreatePAC';
import { StyledTable } from 'components/Table/styled';
import PacMenu from 'components/Menu/PacMenu';
import { ArrowRight, Copy } from 'components/Icons';
import Button from 'components/Button/Button';
import Spin from 'components/Spin';

import PacService, { Pac, PacType } from 'lib/api/pacApi';
import Search from 'antd/lib/input/Search';
import { FilterValue } from 'antd/lib/table/interface';
import { showErrorResponseNotification, getPreviewLinkByPacId } from 'lib/helpers';
import { useAuth0 } from '@auth0/auth0-react';
import {
  PageTitle,
  PageWrapper,
  TitleWrapper,
  Counter,
  Menu,
  ConfigTableTitle,
  TableConfigName,
} from './styled';
import useUserData from '../../lib/hooks/useUserData';

const columns = (
  onDelete: (uuid: string) => void,
  fetchPac: () => void,
  openPacPreview: (uuid: string) => void,
  setCopiedKey: (clicked: boolean) => void,
  copiedKey: boolean,
  deactivatePac: (uuid: string) => void,
  activatePac: (uuid: string) => void,
  onSwitchPacType: (uuid: string, type: PacType) => void,
) => [
  {
    title: 'Configuration Title [ID]',
    key: 'uuid',
    render: (data: Pac) => (
      <ConfigTableTitle style={{ opacity: !!data?.deactivated ? '0.5' : 1 }}>
        <TableConfigName
          data-cy={`pac-${data.uuid}-name-open-link`}
          to={`/configurations/${data.uuid}`}
        >
          <strong>
            {data.title} {data.type === PacType.LITE && <Tag color="blue">AUDIENCE UNLOCKER</Tag>}
          </strong>
        </TableConfigName>
        <br />
        Configuration ID: {data.uuid}{' '}
        <Tooltip title={copiedKey ? 'Copied' : 'Copy to clipboard'} placement="right">
          <Copy
            data-cy={`configuration-list-${data.uuid}-copy-icon`}
            onClick={() => {
              clickToCopy(data.uuid, setCopiedKey);
            }}
          />
        </Tooltip>
      </ConfigTableTitle>
    ),
  },
  {
    title: 'Number of Widgets',
    key: 'widgets',
    width: '200px',
    render: (data: Pac) => (
      <div style={{ textAlign: 'center' }}>
        <Counter style={{ opacity: !!data?.deactivated ? '0.5' : 1, margin: 'auto' }}>
          {data.widgetUuids?.length}
        </Counter>
      </div>
    ),
  },
  {
    title: 'Owner',
    key: 'creatorId',
    render: (data: Pac) => (
      <span style={{ opacity: !!data?.deactivated ? '0.5' : 1 }}>{data.creatorId}</span>
    ),
  },
  {
    title: '',
    key: 'actions',
    width: '120px',
    render: (data: Pac) => (
      <Menu>
        <PacMenu
          dataCy={`pac-${data.uuid}-menu`}
          onPreview={() => openPacPreview(data.uuid)}
          onDeactivate={() => deactivatePac(data.uuid)}
          onActivate={() => activatePac(data.uuid)}
          onSwitchPacType={(type: PacType) => onSwitchPacType(data.uuid, type)}
          pac={data}
          fetchPac={() => fetchPac()}
          onDelete={() => onDelete(data.uuid)}
          isDeleteDisabled={!!data.widgets?.length}
        />
        <NavLink data-cy={`pac-${data.uuid}-open-link`} to={`/configurations/${data.uuid}`}>
          <ArrowRight />
        </NavLink>
      </Menu>
    ),
  },
];
export const PACS_TABLE_PAGE_SIZE = 10;

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Record<string, FilterValue>;
}

const Configurations = () => {
  const { token } = useToken();
  const { user } = useAuth0();
  const [copiedKey, setCopiedKey] = useState<boolean>(false);
  const [isCreateNew, toggleCreateModal] = useState(false);
  const [hasBeenFiltered, setHasBeenFiltered] = useState(false);
  const [searchedTerm, setSearchedTerm] = useState('');
  const [PACList, setPACList] = useState<Array<Pac>>([]);
  const [isDataLoading, setLoading] = useState<boolean>(true);
  const location = useLocation();
  const navigate = useNavigate();
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      pageSize: PACS_TABLE_PAGE_SIZE,
      showSizeChanger: false,
    },
  });
  const { isSuperAdmin } = useUserData();

  const openPacPreview = (pacId: string) => {
    window.open(getPreviewLinkByPacId(pacId), '_blank');
  };

  const fetchPAC = async (currentPage = 1, searchTerm?: string) => {
    try {
      const response = await PacService.get({
        page: currentPage,
        limit: PACS_TABLE_PAGE_SIZE,
        search: searchTerm || undefined,
      });
      setTableParams({
        pagination: {
          current: response.data.currentPage <= 0 ? 1 : response.data.currentPage,
          total: response.data.count,
          pageSize: PACS_TABLE_PAGE_SIZE,
          showSizeChanger: false,
        },
      });
      setPACList(response.data.results.filter((el: Pac) => el !== null));
    } catch (error) {
      showErrorResponseNotification(error);
    } finally {
      setLoading(false);
    }
  };

  const onDeletePac = async (pacUuid: string) => {
    setLoading(true);

    try {
      await PacService.delete(pacUuid);
      message.success('Configuration has been successfully deleted.');
      const pacsAfterDelete = PACList.filter((pac) => pac.uuid !== pacUuid);

      if (
        pacsAfterDelete.length === 0 &&
        tableParams?.pagination?.current &&
        tableParams?.pagination?.current > 1
      ) {
        const decreasePagination = tableParams?.pagination?.current;
        fetchPAC(decreasePagination - 1, searchedTerm);
        return;
      }

      setPACList(PACList.filter((pac) => pac.uuid !== pacUuid));
      setLoading(false);
    } catch (error) {
      showErrorResponseNotification(error);
      fetchPAC(tableParams?.pagination?.current, searchedTerm);
      setLoading(false);
    }
  };

  const onDeactivate = async (pacUuid: string) => {
    setLoading(true);

    try {
      await PacService.deactivate(pacUuid);

      setPACList(
        PACList.map((pac) => {
          if (pac.uuid === pacUuid) {
            return {
              ...pac,
              deactivated: true,
            };
          }
          return pac;
        }),
      );
      message.success('Configuration set to INACTIVE');
    } catch (error) {
      showErrorResponseNotification(error);
    } finally {
      setLoading(false);
    }
  };

  const onActivate = async (pacUuid: string) => {
    setLoading(true);

    try {
      await PacService.activate(pacUuid);
      setPACList(
        PACList.map((pac) => {
          if (pac.uuid === pacUuid) {
            return {
              ...pac,
              deactivated: false,
            };
          }
          return pac;
        }),
      );
      message.success('Configuration set to ACTIVE.');
    } catch (error) {
      showErrorResponseNotification(error);
    } finally {
      setLoading(false);
    }
  };

  const onSwitchPacType = async (pacUuid: string, type: PacType) => {
    setLoading(true);

    try {
      await PacService.switchPacVersion(pacUuid, type);

      setPACList(
        PACList.map((pac): Pac => {
          if (pac.uuid === pacUuid) {
            return {
              ...pac,
              type,
            };
          }
          return pac;
        }),
      );
      message.success(`Configuration set to ${type} version`);
    } catch (error) {
      showErrorResponseNotification(error);
    } finally {
      setLoading(false);
    }
  };

  const onSearch = async (searchString: string) => {
    const trimmedSearchString = searchString.trim();
    setSearchedTerm(trimmedSearchString);
    if (trimmedSearchString !== '') {
      await fetchPAC(1, trimmedSearchString);
      setHasBeenFiltered(true);
    } else if (hasBeenFiltered) {
      await fetchPAC();
      setHasBeenFiltered(false);
    }
  };

  const handleTableChange = (pagination: TablePaginationConfig) => {
    setLoading(true);
    setTableParams({
      pagination,
    });

    fetchPAC(pagination.current, searchedTerm);
  };

  useEffect(() => {
    if (!token) {
      return;
    }

    if (
      !location.pathname.includes('onboarding') &&
      localStorage.getItem('returnTo') === 'onboarding'
    ) {
      localStorage.removeItem('returnTo');
      navigate('/onboarding/step-1');
    }
    fetchPAC();
  }, [token]);

  return isDataLoading ? (
    <Spin />
  ) : (
    <div>
      <TitleWrapper>
        <PageTitle>Configurations</PageTitle>
        {(PACList?.length > 0 || hasBeenFiltered) && isSuperAdmin && (
          <Button type="primary" onClick={() => toggleCreateModal(true)}>
            Add Configuration
          </Button>
        )}

        {PACList?.filter((pac) => pac.creatorId === user?.email && pac.type === PacType.LITE)
          .length === 0 &&
          !isSuperAdmin && (
            <Button type="primary" onClick={() => navigate('/onboarding')}>
              Try Audience Unlocker!
            </Button>
          )}
      </TitleWrapper>
      <PageWrapper>
        {PACList.length > 0 || hasBeenFiltered ? (
          <>
            <Search
              data-cy="configuration-search-input"
              placeholder="Search for configuration"
              size="large"
              onSearch={onSearch}
              defaultValue={searchedTerm}
              style={{ marginBottom: '20px' }}
            />
            <StyledTable
              data-cy="pac-list-table"
              autoHeight
              isRemovePadding
              dataSource={PACList}
              columns={columns(
                onDeletePac,
                () => fetchPAC(tableParams?.pagination?.current, searchedTerm),
                openPacPreview,
                setCopiedKey,
                copiedKey,
                onDeactivate,
                onActivate,
                onSwitchPacType,
              )}
              onChange={handleTableChange}
              pagination={tableParams.pagination}
              borderRadius="none"
              rowKey="uuid"
              onRow={() => {
                return {
                  //onClick: () => redirect((record as any)?.uuid),
                };
              }}
            />
          </>
        ) : (
          <EmptyListPlaceholder
            title="Welcome"
            image={artBoardImage}
            description={
              <span>
                <p>
                  Currently there are no Preference Management Center configurations available for
                  this user.
                </p>
                <p>
                  If you think you should have an access to any existing configurations, please
                  contact our{' '}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://usercentrics.atlassian.net/servicedesk/customer/portals"
                  >
                    support
                  </a>
                  .
                </p>
              </span>
            }
            link={{
              text: 'Documentation',
              to: 'https://docs.usercentrics.com/#/preference-manager',
              dataId: 'documentation',
            }}
            button={
              isSuperAdmin
                ? {
                    text: 'Add Configuration',
                    onClick: () => toggleCreateModal(true),
                    dataId: 'addPAC',
                  }
                : null
            }
          />
        )}
      </PageWrapper>
      {isCreateNew && (
        <CreatePACModal
          addNewPac={(newPAC: Pac) => {
            setPACList([...PACList, newPAC]);
            toggleCreateModal(false);
          }}
          onClose={() => {
            toggleCreateModal(false);
          }}
        />
      )}
    </div>
  );
};

export default Configurations;
