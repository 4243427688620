import React, { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';

import { message, Typography, Form } from 'antd';
import { useNavigate } from 'react-router-dom';
import Logo from 'components/Logo';

import Button from 'components/Button/Button';
import { Google as GoogleIcon } from 'components/Icons';
import Spin from 'components/Spin';

import { OnboardingStorageService } from 'lib/services/onboardingStorageService';
import GCMIntegrationApiService from 'lib/api/googleCustomerMatchIntegrationApi';
import { showErrorResponseNotification } from 'lib/helpers';
import { numericTenSymbols } from 'lib/regex/validations';

import PacService, { Pac } from 'lib/api/pacApi';

import { IGcmDetailsData } from 'lib/interfaces/googleCustomerMatch';
import { StyledSelect, Option } from 'components/Select/Select';
import check from 'assets/check.svg';
import {
  StyledLogo,
  Wrapper,
  Sidebar,
  StyledText,
  FlexWrapper,
  FormWrapper,
  TopButtons,
  InfoText,
  OnboardingContent,
  GoogleCustomerAdsContainer,
  GoogleUserDataContainer,
  LoggedInAsContainer,
  Circle,
  ScrollableContainer,
  OnboardingSidebarTitle,
} from './styled';
import { HeaderSection } from './HeaderSection';

const { Title } = Typography;

const Page = () => {
  const navigate = useNavigate();
  const [isDataLoading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();

  const [googleAccount, setGoogleAccount] = useState<string | null>(null);
  const [gcmDetailsData, setGcmDetailsData] = useState<Array<IGcmDetailsData>>();
  const [selectedAdsAccount, setSelectedAdsAccount] = useState<IGcmDetailsData>();
  const [googleToken, setGoogleToken] = useState<string | null>(null);
  const [adsAccount, setAdsAccount] = useState<string>('');
  const [customerListId, setCustomerListId] = useState<string>('');

  const result = OnboardingStorageService.getOnboardingResults();

  const [pacData, setPacData] = useState<Pac>({} as Pac);

  const getPacInfo = async (id: string) => {
    setLoading(true);
    try {
      const response: AxiosResponse<Pac> = await PacService.getById(id);
      setPacData(response.data);
    } catch (error) {
      showErrorResponseNotification(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (result.pacUuid === '') navigate('/onboarding/step-1');
    getPacInfo(result.pacUuid);
  }, []);

  const login = useGoogleLogin({
    flow: 'auth-code',
    scope: 'openid email profile https://www.googleapis.com/auth/adwords',
    onSuccess: async (response) => {
      if (
        response.scope.includes('email') &&
        response.scope.includes('https://www.googleapis.com/auth/adwords')
      ) {
        try {
          const credentialsResponse = await GCMIntegrationApiService.getCredentials(response.code);

          setGoogleAccount(credentialsResponse.data.email);
          setGoogleToken(credentialsResponse.data.refreshToken);
          const { data } = await GCMIntegrationApiService.getCustomerDataByRefreshToken(
            credentialsResponse.data.refreshToken,
          );
          setGcmDetailsData(data);
          message.success('Google account connected successfully!');
        } catch (error) {
          showErrorResponseNotification(error);
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
        message.error('Requested scopes are not granted');
      }
    },
    onError: () => {
      setLoading(false);

      message.error('Google login failed. Please try again.');
    },
  });

  const onFinish = async () => {
    if (googleAccount && adsAccount && customerListId) {
      if (
        !numericTenSymbols.pattern.test(adsAccount) ||
        !numericTenSymbols.pattern.test(customerListId)
      ) {
        message.error('Customer ID or Customer List ID are empty');
        return null;
      }
      setLoading(true);

      try {
        await GCMIntegrationApiService.createConfig({
          pacUuid: result.pacUuid,
          title: 'Connector to Google Customer Match',
          consentSignalsSource: {
            widgetUuid: pacData.widgets[0].uuid,
            topicUuid: pacData.widgets[0].topics[0].uuid,
            optionUuidsFor: {
              userData: pacData.widgets[0].topics[0].options[0].uuid,
              personalization: pacData.widgets[0].topics[0].options[0].uuid,
            },
          },
          refreshToken: googleToken || '',
          email: googleAccount || '',
          customerId: adsAccount,
          customerListId,
        });
        message.success('You’ve successfully configured a connection with Google Customer Match!');
        return navigate('/onboarding/step-5');
      } catch (error) {
        showErrorResponseNotification(error);
        return null;
      } finally {
        setLoading(false);
      }
    } else {
      return navigate('/onboarding/step-5');
    }
  };

  return (
    <Wrapper>
      <Sidebar>
        <StyledLogo>
          <Logo />
        </StyledLogo>
        <div>
          <StyledText>Preference Manager Audience Unlocker</StyledText>
          <OnboardingSidebarTitle level={1}>Connect your marketing platform</OnboardingSidebarTitle>
          <span>
            Create a trusted link between the Audience Unlocker and your marketing platform, then
            select the custom audience list for automatic data transfer.
          </span>
        </div>
      </Sidebar>
      {isDataLoading ? (
        <OnboardingContent>
          <Spin />
        </OnboardingContent>
      ) : (
        <OnboardingContent>
          <HeaderSection activeStepIndex={3} />
          <Title level={2}>Connect to Google Ads</Title>
          <Title level={4} style={{ margin: 0, color: '#595959', fontWeight: 400 }}>
            You have successfully created your new configuration. To send consented data from your{' '}
            <br /> Preference Center directly to Google marketing platforms, sign in with Google and
            connect <br /> your Google Ads account.
          </Title>
          <ScrollableContainer style={{ alignSelf: 'center' }}>
            <div>
              <FlexWrapper>
                <FormWrapper style={{ backgroundColor: 'white', width: '100%', border: 0 }}>
                  {googleAccount ? (
                    <GoogleUserDataContainer>
                      <div>
                        <LoggedInAsContainer>
                          <span>
                            You are logged in as <strong>{googleAccount}</strong>
                          </span>
                          <Circle>
                            <img src={check} alt="check" />
                          </Circle>
                        </LoggedInAsContainer>
                        <Button
                          type="link"
                          onClick={() => setGoogleAccount(null)}
                          style={{ margin: 'auto', textDecoration: 'underline' }}
                        >
                          Change Account
                        </Button>
                      </div>
                      <GoogleCustomerAdsContainer>
                        <p>
                          Choose the Google Ads account you want to use and select the Google
                          Customer List where you want to save the captured user data.
                        </p>
                        <Form layout="vertical" style={{ width: '100%' }} form={form}>
                          <Form.Item
                            name="customerId"
                            label="Use Google Ads as"
                            rules={[
                              {
                                required: true,
                                message: 'Please select your Google Ads Customer ID.',
                              },
                            ]}
                          >
                            <StyledSelect
                              placeholder="Google Ads Account (CustomerID)"
                              onChange={(value: any) => {
                                setSelectedAdsAccount(gcmDetailsData?.find((d) => d.id === value));
                                setAdsAccount(value.toString());
                                setCustomerListId('');
                                form.setFieldValue('customerListId', '');
                              }}
                            >
                              {gcmDetailsData?.map((details) => (
                                <Option key={details.id} value={details.id}>
                                  {details.name}
                                </Option>
                              ))}
                            </StyledSelect>
                          </Form.Item>
                          <Form.Item
                            name="customerListId"
                            label="Select customer list"
                            rules={[
                              { required: true, message: 'Please select your Customer List ID.' },
                            ]}
                          >
                            <StyledSelect
                              placeholder="Customer List ID"
                              onChange={(value: any) => setCustomerListId(value.toString())}
                            >
                              {selectedAdsAccount?.customerList?.map((details) => (
                                <Option key={details.id} value={details.id}>
                                  {details.name} ({details.id})
                                </Option>
                              ))}
                            </StyledSelect>
                          </Form.Item>
                        </Form>
                      </GoogleCustomerAdsContainer>
                    </GoogleUserDataContainer>
                  ) : (
                    <div data-cy="google-login-block">
                      <Button
                        type="white"
                        size="large"
                        onClick={() => {
                          setLoading(true);
                          login();
                        }}
                        style={{ margin: 'auto', marginBottom: '10px', borderRadius: '100px' }}
                        data-cy="google-login-button"
                      >
                        <GoogleIcon /> Sign in with Google
                      </Button>
                      <InfoText style={{ textAlign: 'center', marginTop: '32px' }}>
                        You can also set it up later directly through our Admin Interface
                      </InfoText>
                    </div>
                  )}
                </FormWrapper>
              </FlexWrapper>
            </div>
          </ScrollableContainer>
          <TopButtons style={{ justifyContent: 'right' }}>
            <Button
              type={
                !!(googleAccount && (adsAccount === '' || customerListId === ''))
                  ? 'default'
                  : 'black'
              }
              size="large"
              onClick={onFinish}
              htmlType="submit"
              data-cy="button-finishSetup"
              disabled={!!(googleAccount && (adsAccount === '' || customerListId === ''))}
            >
              Next
            </Button>
          </TopButtons>
        </OnboardingContent>
      )}
    </Wrapper>
  );
};

const Step3 = () => (
  <GoogleOAuthProvider clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}>
    <Page />
  </GoogleOAuthProvider>
);

export default Step3;
