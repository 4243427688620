/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { useToken, useMessage } from 'lib/hooks';
import Button from 'components/Button/Button';

import UserDetailsModal, { Answer, transformDate } from 'components/Modal/UserDetails';
import Spin from 'components/Spin';
import { StyledSelect as Select } from 'components/Select/Select';
import DeleteConfirmationModal from 'components/Modal/DeleteConfirmation';
import { Item, Input } from 'components/Form';
import { StyledTable } from 'components/Table/styled';
import { LoadingType } from 'lib/enums/steps';
import WidgetApiService from 'lib/api/widgetApi';
import UsercentricsCmpIntegrationApiService from 'lib/api/usercentricsCmpIntegration';
import PacService, { Pac } from 'lib/api/pacApi';
import { ICmpSession } from 'lib/interfaces/cmpIntegration';
import { exportToCsv, showErrorResponseNotification } from 'lib/helpers';
import CustomerService, { PayloadData, PreferencesRequest } from 'lib/api/customerApi';
import { validate } from 'uuid';
import { Form } from 'antd';
import Menu from 'components/Menu/Menu';
import { PageTitle, PageWrapper } from '../PAC/styled';

import {
  SearchContainer,
  InputContainer,
  EmptyUserData,
  Text,
  TableContainer,
  ResultsHeader,
  TextInputContainer,
} from './styled';
import useUserData from '../../lib/hooks/useUserData';

interface IPAC {
  uuid: string;
  title: string;
}

interface UserDetailsModalPreference {
  answers: Array<Answer>;
  widgetUuid: string;
  uuid: string;
  userId: string;
  updatedAt: string;
  expiresAt?: string;
}

type RowData = PayloadData & {
  type?: string;
};

const PreferenceData = () => {
  const [searchForm] = Form.useForm();
  const message = useMessage();
  const { isSuperAdmin } = useUserData();
  const { token } = useToken();
  const [userData, setUserData] = useState<Array<PayloadData>>([]);
  const [currantModalUser, setCurrantModalUser] = useState<UserDetailsModalPreference>({
    answers: [],
    widgetUuid: '',
    uuid: '',
    userId: '',
    updatedAt: '',
  });
  const [userDetailsModal, setUserDetailsModal] = useState(false);
  const [deleteCMPSessionModal, setDeleteCMPSessionModal] = useState(false);
  const [deletePreferenceUuid, setDeletePreferenceUuid] = useState<string | null>();

  const [sessionToDelete, setSessionToDelete] = useState('');
  const [PACList, setPACList] = useState<Array<IPAC>>([]);
  const [CMPList, setCMPList] = useState<Array<ICmpSession>>([]);

  const [isDataLoading, setLoading] = useState<LoadingType | false>(LoadingType.PACS);
  let pacToSearch =
    searchForm.getFieldValue('selectConfigurationId') ||
    searchForm.getFieldValue('configurationId');
  let tableContent: any[] = [];

  const fetchPAC = async () => {
    const response: AxiosResponse = await PacService.get({ short: true, limit: 200 });

    setPACList(response.data.results?.map((pac: Pac) => ({ uuid: pac.uuid, title: pac.title })));
    setLoading(false);
  };

  const fetchPreferenceData = async (id = '') => {
    if (!id) {
      return;
    }

    try {
      const response: AxiosResponse<PreferencesRequest> = await CustomerService.getPreferences(id, {
        pacUuid: pacToSearch,
      });

      // eslint-disable-next-line no-restricted-syntax
      for await (const payload of response.data.payload) {
        try {
          const result = await WidgetApiService.getById(payload.widgetUuid);
          payload.widgetName = result.data.title as string;
        } catch (e) {
          setLoading(false);
          throw new Error(`No data found for widget ${payload.widgetUuid}`);
        }
      }

      const mappedPayload = response.data.payload.map((data: PayloadData) => {
        return {
          ...data,
          userId: id,
        };
      });

      setUserData(mappedPayload);
      tableContent = [...tableContent, ...mappedPayload];

      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      message.error(error?.response?.data?.message);
    }
  };

  const fetchCMPSessions = async () => {
    try {
      const response = await UsercentricsCmpIntegrationApiService.getSessionByPacUuid(
        searchForm.getFieldValue('userId'),
        pacToSearch,
      );
      const data = response.data.filter((session: any) => {
        return session.controllerId !== undefined;
      });

      if (data.length) {
        setCMPList(response.data);
        tableContent = [...tableContent, ...response.data];
      } else {
        setCMPList([]);
      }
    } catch (error) {
      showErrorResponseNotification(error);
    }
  };

  const search = async () => {
    pacToSearch =
      searchForm.getFieldValue('selectConfigurationId') ||
      searchForm.getFieldValue('configurationId');
    setLoading(LoadingType.PREFERENCES);
    tableContent = [];
    await fetchCMPSessions();
    await fetchPreferenceData(searchForm.getFieldValue('userId'));
    if (tableContent.length <= 0) {
      message.warning('No data found for User ID provided');
    }
  };

  const deleteCmpSession = async () => {
    try {
      await UsercentricsCmpIntegrationApiService.deleteSessionById(sessionToDelete);
      fetchCMPSessions();
      setDeleteCMPSessionModal(false);
    } catch (error) {
      showErrorResponseNotification(error);
    }
  };

  const onPreferenceDelete = async (preferenceUuid: string) => {
    try {
      await CustomerService.deletePreferenceById(preferenceUuid);
      await fetchPreferenceData(searchForm.getFieldValue('userId'));
      message.success('Data has been successfully deleted.');
      setDeletePreferenceUuid(null);
    } catch (error) {
      showErrorResponseNotification(error);
    }
  };

  const onExportPreferenceData = async (userId: string, widgetUuid: string) => {
    try {
      const response = await CustomerService.getWidgetExportDataByUserId(userId, {
        widgetId: widgetUuid,
      });
      exportToCsv(response.data, `pmp_user_data_${widgetUuid}_${Date.now()}.csv`);
      // Is asking for defining for any
    } catch (error: any) {
      if (error.response.status === 422) {
        message.error('There is no preference data for this widget');
      } else {
        showErrorResponseNotification(error);
      }
    }
  };

  const onExportCmpData = async (settingsId: string, controllerId: string) => {
    window.open(
      `${process.env.REACT_APP_CONSENT_API_URL}/export?settingsId=${settingsId}&controllerId=${controllerId}`,
      '_blank',
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!token) {
        return;
      }
      fetchPAC();
    };
    fetchData();
  }, [token]);

  const columns = [
    {
      title: 'User ID',
      dataIndex: 'userId',
      key: 'userId',
    },
    {
      title: 'Widget Name',
      dataIndex: 'widgetName',
      key: 'widgetName',
    },
    {
      title: 'Submission Date',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (date: string) => transformDate(date),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (data: RowData) =>
        data.type !== 'cmp' ? (
          <Menu defaultIcon dataCy="preference-data-menu">
            <Menu.Item
              data-cy="preference-data-view-details-button"
              key="view"
              onClick={() => {
                setCurrantModalUser(data);
                setUserDetailsModal(true);
              }}
            >
              View Details
            </Menu.Item>
            <Menu.Item
              data-cy="preference-data-export-button"
              key="export"
              onClick={() => {
                onExportPreferenceData(data.userId, data.widgetUuid);
              }}
            >
              Export CSV
            </Menu.Item>
            <Menu.Item
              data-cy="preference-data-delete-button"
              key="delete"
              delete
              onClick={() => {
                setDeletePreferenceUuid(data.uuid);
              }}
            >
              Delete
            </Menu.Item>
          </Menu>
        ) : (
          <Menu defaultIcon dataCy="preference-data-menu">
            <Menu.Item
              data-cy="preference-data-export-cmp-button"
              key="exportCmp"
              onClick={() => {
                onExportCmpData(data.settingsId as string, data.controllerId as string);
              }}
            >
              Export CSV
            </Menu.Item>
            <Menu.Item
              data-cy="preference-data-delete-cmp-session-button"
              key="deleteCmp"
              delete
              onClick={() => {
                setCurrantModalUser(data);
                setSessionToDelete(data.uuid);
                setDeleteCMPSessionModal(true);
              }}
            >
              Delete
            </Menu.Item>
          </Menu>
        ),
    },
  ];

  return isDataLoading === LoadingType.PACS ? (
    <Spin />
  ) : (
    <div>
      <PageTitle>Preference Data</PageTitle>
      <PageWrapper>
        {deleteCMPSessionModal && (
          <DeleteConfirmationModal
            onDelete={() => deleteCmpSession()}
            primaryButtonLabel="Delete"
            onClose={() => setDeleteCMPSessionModal(false)}
            title="Delete CMP Session"
            text="Are you sure you want to delete the link to Cookie consent session for this user? Once deleted, user will not be able to restore this specific consent session!<br>Please note, this action will not delete the actual consent data from our database."
            primaryButtonDanger
            width={480}
            hideFooterBorder={false}
            isDefaultFooter={false}
          />
        )}
        {deletePreferenceUuid && (
          <DeleteConfirmationModal
            onDelete={() => {
              onPreferenceDelete(deletePreferenceUuid);
            }}
            onClose={() => setDeletePreferenceUuid(null)}
            primaryButtonLabel="Delete"
            primaryButtonDanger
            width={480}
            hideFooterBorder={false}
            isDefaultFooter={false}
            title="Preference Data Deletion"
            text="Are you sure you want to delete all preference data captured for this user?"
          />
        )}
        {userDetailsModal && (
          <UserDetailsModal
            preference={currantModalUser}
            onClose={() => {
              setUserDetailsModal(false);
              fetchPreferenceData(searchForm.getFieldValue('userId'));
            }}
          />
        )}
        <Form form={searchForm} onFinish={search} validateTrigger="onSubmit">
          <SearchContainer>
            <Item
              name="selectConfigurationId"
              validateTrigger="onBlur"
              style={{ width: '30%', marginRight: '10px' }}
              rules={[
                ({ getFieldValue }: { getFieldValue: any }) => ({
                  validator(_: any, value: string) {
                    if (validate(getFieldValue('configurationId'))) {
                      return Promise.resolve();
                    }
                    if (!validate(value)) {
                      return Promise.reject(new Error('Please select a configuration'));
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Select
                placeholder="Select Configuration"
                data-cy="preference-data-configuration-id-select"
              >
                {PACList.map((pac: IPAC) => (
                  <Select.Option
                    key={pac.uuid}
                    value={pac.uuid}
                    data-cy={`preference-data-configuration-${pac.uuid}-option`}
                  >
                    {pac.title} [{pac.uuid}]
                  </Select.Option>
                ))}
              </Select>
            </Item>
            {isSuperAdmin && (
              <>
                <TextInputContainer>or</TextInputContainer>
                <InputContainer>
                  <Item
                    name="configurationId"
                    validateTrigger="onBlur"
                    required
                    rules={[
                      ({ getFieldValue }: { getFieldValue: any }) => ({
                        validator(_: any, value: string) {
                          if (validate(getFieldValue('selectConfigurationId'))) {
                            return Promise.resolve();
                          }
                          if (!validate(value)) {
                            return Promise.reject(new Error('Configuration ID must be a UUID'));
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Input
                      placeholder="Enter configuration ID"
                      data-cy="preference-data-user-configuration-id-input"
                    />
                  </Item>
                </InputContainer>
              </>
            )}
            <InputContainer>
              <Item
                name="userId"
                validateTrigger="onBlur"
                rules={[{ required: true, message: 'User-ID is mandatory' }]}
              >
                <Input placeholder="Enter User-ID" data-cy="preference-data-user-id-input" />
              </Item>
            </InputContainer>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              data-cy="preference-data-search-button"
            >
              Search
            </Button>
          </SearchContainer>
        </Form>
        {isDataLoading === LoadingType.PREFERENCES ? (
          <Spin />
        ) : userData.length || CMPList.length ? (
          <TableContainer>
            <ResultsHeader>
              <p>Results</p>
            </ResultsHeader>
            <StyledTable
              autoHeight
              pagination={{
                showSizeChanger: false,
              }}
              isRemovePadding
              dataSource={[
                ...userData,
                ...CMPList.map((cmp: ICmpSession) => ({
                  widgetName: cmp.title,
                  uuid: cmp.uuid,
                  updatedAt: cmp.createdAt,
                  settingsId: cmp.settingsId,
                  controllerId: cmp.controllerId,
                  type: 'cmp',
                  userId: searchForm.getFieldValue('userId'),
                })),
              ]}
              columns={columns}
              borderRadius="none"
            />
          </TableContainer>
        ) : (
          <EmptyUserData>
            <Text>Please enter any User-ID</Text>
          </EmptyUserData>
        )}
      </PageWrapper>
    </div>
  );
};

export default PreferenceData;
