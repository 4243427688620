import React from 'react';
import { User as UserPlaceholder } from 'components/Icons';
import {
  DateLabel,
  DropdownItem,
  UserInfoWrapper,
  UserName,
  UserWrapper,
} from 'components/Header/styled';
import check from 'assets/check.svg';
import { Dropdown, MenuProps } from 'antd';
import { useAuth0 } from '@auth0/auth0-react';
import { useLogout } from 'lib/hooks';
import { OnboardingStorageService } from 'lib/services/onboardingStorageService';
import { Circle, HeaderStepper, Step, Stepper, StepperContainer } from './styled';

interface IHeaderSection {
  activeStepIndex: number;
}

export const HeaderSection = ({ activeStepIndex }: IHeaderSection) => {
  const { user } = useAuth0();
  const logOut = useLogout(true);
  const userMenuItems: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <UserInfoWrapper>
          <UserPlaceholder />
          <div>
            <UserName>{user?.name}</UserName>
            <p>{user?.email}</p>
            {user?.updated_at && (
              <DateLabel>
                Last login: {new Date(user?.updated_at).toLocaleString('en-GB')}
              </DateLabel>
            )}
          </div>
        </UserInfoWrapper>
      ),
    },
    {
      key: '2',
      type: 'divider',
    },
    {
      key: '3',
      label: (
        <DropdownItem
          key="logout"
          onClick={() => {
            OnboardingStorageService.setStep1Data({
              title: '',
              description: '',
              widgetTitle: '',
              widgetDescription: '',
              logoUrl: undefined,
              doubleOptIn: false,
            });
            OnboardingStorageService.setStep2Data({
              formSelector: '',
              userIdSelector: '',
              consentSelector: '',
            });
            logOut();
          }}
        >
          Logout
        </DropdownItem>
      ),
    },
  ];
  const stepperList = ['Welcome', 'Configure', 'Generate Script', 'Connect to Google Ads'];
  return (
    <HeaderStepper>
      <StepperContainer>
        <Stepper>
          {stepperList.map((step, index) => {
            return (
              <Step
                {...(index === activeStepIndex ? { active: true } : {})}
                {...(index < activeStepIndex ? { processed: true } : {})}
                key={`step-${step}`}
              >
                <Circle>{index < activeStepIndex && <img src={check} alt="check" />}</Circle>
                {step}
              </Step>
            );
          })}
        </Stepper>
      </StepperContainer>
      <UserWrapper style={{ borderLeft: '0' }}>
        <Dropdown
          menu={{ items: userMenuItems }}
          placement="bottomRight"
          align={{ offset: [-10, 0] }}
        >
          <span className="ant-dropdown-link">
            <UserPlaceholder />
          </span>
        </Dropdown>
      </UserWrapper>
    </HeaderStepper>
  );
};
