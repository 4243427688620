import React, { useState } from 'react';
import { Form, Input, Typography } from 'antd';
import PacService from 'lib/api/pacApi';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import Logo from 'components/Logo';
import { showErrorResponseNotification } from 'lib/helpers';
import { OnboardingStorageService } from 'lib/services/onboardingStorageService';
import Button from 'components/Button/Button';
import Spin from 'components/Spin';
import FormCollector from 'assets/FormCollector.svg';
import {
  StyledLogo,
  Wrapper,
  Sidebar,
  TopButtons,
  StyledText,
  FormWrapper,
  FlexWrapper,
  ExampleContainer,
  ExampleTitle,
  ExampleImage,
  OnboardingContent,
  ScrollableContainer,
  OnboardingSidebarTitle,
} from './styled';
import { HeaderSection } from './HeaderSection';

const { Title } = Typography;

const StyledForm = styled(Form)`
  max-width: 500px;
  margin-top: 20px;
`;

const InfoText = styled.div`
  margin-bottom: 20px;
  font-size: 14px;
  color: #555;

  & a {
    color: ${(props) => props.theme.colors.primary};
  }
`;

const CreateConfigurationStep2 = () => {
  const [isDataLoading, setLoading] = useState<boolean>(false);

  const [form] = Form.useForm();
  const navigate = useNavigate();

  const onFinish = async (values: any) => {
    try {
      setLoading(true);
      OnboardingStorageService.setStep2Data(values);
      const step1Data = OnboardingStorageService.getStep1Data();

      const response = await PacService.createPacLite({
        ...step1Data,
        formDataCollectorInfo: {
          consentSelector: values.consentSelector || '',
          formSelector: values.formSelector || '',
          userIdSelector: values.userIdSelector || '',
        },
      });
      OnboardingStorageService.setOnboardingResults(response.data);
      return navigate('/onboarding/step-4');
    } catch (error) {
      return showErrorResponseNotification(error);
    } finally {
      setLoading(false);
    }
  };

  const onNextStep = () => {
    form.submit();
  };

  const onClickBack = () => navigate('/onboarding/step-2');

  return (
    <Wrapper>
      <Sidebar>
        <StyledLogo>
          <Logo />
        </StyledLogo>
        <div>
          <StyledText>Preference Manager Audience Unlocker</StyledText>
          <OnboardingSidebarTitle level={1}>
            Integrate with your existing web forms
          </OnboardingSidebarTitle>
          <span>
            Use your existing web registration forms to capture users consent signals and details
            for retargeting purposes.
          </span>
        </div>
      </Sidebar>
      {isDataLoading ? (
        <OnboardingContent>
          <Spin />
        </OnboardingContent>
      ) : (
        <OnboardingContent>
          <HeaderSection activeStepIndex={2} />
          <Title level={2}>Data capture</Title>
          <Title level={4} style={{ margin: 0, color: '#595959', fontWeight: 400 }}>
            Provide details about the form on your website used for capturing user data and we will{' '}
            <br />
            generate a code to plug into your form.
          </Title>
          <ScrollableContainer style={{ marginTop: '20px' }}>
            <div>
              <FlexWrapper>
                <ExampleContainer>
                  <ExampleTitle>Locate form field IDs</ExampleTitle>
                  <ExampleImage style={{ padding: '20px', paddingTop: 0, paddingBottom: 0 }}>
                    <img style={{ width: '100%' }} src={FormCollector} alt="Example" />
                  </ExampleImage>
                </ExampleContainer>
                <FormWrapper>
                  <Title level={5} style={{ fontSize: '14px', fontWeight: 500 }}>
                    Provide details about your data capture form
                  </Title>
                  <InfoText>
                    In your browser, right-click on your form field, select &quot;Inspect&quot;,
                    review the form element&apos;s HTML code to find its IDs and paste them in the
                    fields below.
                    <br />
                    <br />
                    Still confused? You can skip this step, check our{' '}
                    <a
                      href="https://support.usercentrics.com/hc/en-us/articles/16742483326620-Setting-up-your-Audience-Unlocker"
                      target="_blank"
                      rel="noreferrer"
                    >
                      documentation
                    </a>{' '}
                    and configure it later.
                  </InfoText>
                  <StyledForm form={form} layout="vertical" onFinish={onFinish}>
                    <Form.Item
                      label="Capture Form ID"
                      name="formSelector"
                      data-cy="input-formScript"
                      tooltip="Enter the unique  ID of the HTML form you want to integrate with. This connects our system to the form on your website."
                    >
                      <Input placeholder="data_form" />
                    </Form.Item>

                    <Form.Item
                      label="Email field ID"
                      name="userIdSelector"
                      data-cy="input-emailField"
                      tooltip="Provide the ID of the input field where users enter their email address. This enables accurate data capture during integrations."
                    >
                      <Input placeholder="user_email" />
                    </Form.Item>

                    <Form.Item
                      label="Consent field ID"
                      name="consentSelector"
                      data-cy="input-consentField"
                      tooltip="Input the ID of the checkbox where users give their consent. Ensuring this ID is accurate helps you stay compliant with data collection laws."
                    >
                      <Input placeholder="consent_field" />
                    </Form.Item>
                  </StyledForm>
                </FormWrapper>
              </FlexWrapper>
            </div>
          </ScrollableContainer>
          <TopButtons>
            <Button type="white" size="large" onClick={onClickBack}>
              Back
            </Button>
            <Button
              type="black"
              size="large"
              onClick={onNextStep}
              htmlType="submit"
              data-cy="button-finishSetup"
            >
              Next Step
            </Button>
          </TopButtons>
        </OnboardingContent>
      )}
    </Wrapper>
  );
};

export default CreateConfigurationStep2;
