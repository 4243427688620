/* eslint-disable no-console */
import React, { useState } from 'react';
import { Input } from 'components/Form';
import Button from 'components/Button/Button';
import { Flex } from 'shared-components';
import { message } from 'antd';

import HeaderComponent from 'components/Header';
import PreferenceManagerApi from 'lib/api/permissionManagerApi';
import { useLogout } from 'lib/hooks';
import { Top, Wrapper, Title, Message, Container } from './styled';

interface IProps {
  error: string;
}

const LoginButton = () => {
  const logOut = useLogout();
  return (
    <button type="button" onClick={() => logOut()}>
      login
    </button>
  );
};
const NotVerifiedError = () => {
  const [email, changeEmail] = useState('');
  const [messageSent, switchMessageStatus] = useState(false);

  return messageSent ? (
    <>
      <Title>Verify your email</Title>
      <Message>
        Verification mail was sent, it may take up to 5 minutes, please check your inbox. Once
        verification is done, please try to <LoginButton /> again.
      </Message>
    </>
  ) : (
    <>
      <Title>Verify your email</Title>
      <Message>
        Your email address needs to be verified, kindly check your inbox. Once verification is done,
        please try to <LoginButton /> again.
      </Message>
      <Message>
        If you didn&apos;t receive verification mail, please fill input with your email and press
        resend button.
      </Message>
      <Flex>
        <Input onChange={(e) => changeEmail(e.target.value)} value={email} />
        <Button
          onClick={async () => {
            try {
              await PreferenceManagerApi.verifyUser(email);
              switchMessageStatus(true);
            } catch (error) {
              message.error('Please enter correct email address');
            }
          }}
          type="primary"
        >
          Resend
        </Button>
      </Flex>
    </>
  );
};

const AuthErrorPage = ({ error }: IProps) => {
  return (
    <Wrapper>
      <HeaderComponent hideInfo />
      <Top style={{ height: '260px' }}>
        <Container>
          {error === 'ERROR_NOT_VERIFIED' ? (
            <NotVerifiedError />
          ) : (
            <>
              <Title>Something&apos;s wrong here.</Title>
              <Message>
                {error?.endsWith('.') ? error : `${error}.`} Please try to <LoginButton /> again
                later.
              </Message>
            </>
          )}
        </Container>
      </Top>
    </Wrapper>
  );
};

export default AuthErrorPage;
