import React from 'react';
import CodeSnippet from '..';

const GenerateDataCollectorScript = ({
  widgetUuid,
  pacUuid,
  publicApiKey,
  optionUuid,
  consentSelector,
  userIdSelector,
  formSelector,
}: {
  widgetUuid: string;
  pacUuid: string;
  publicApiKey: string;
  optionUuid: string;
  consentSelector: string;
  userIdSelector: string;
  formSelector: string;
}) => (
  <CodeSnippet
    copyContent={`<script>
  // Attach the preference management event listener
  window.setupForm({
    event: {
      // The element selector that you want to listen for an event, can be a form, button, etc
      // Use CSS selectors like:
      // - .theElementClass
      // - #theElementId
      // - div.anything > form
      elementSelector: '#${formSelector || '[INSERT_FORM-ID_HERE]'}', // The form to capture preferences
      eventType: "submit", // The event type, usually form submission
      observeDomChanges: false,
    },
    widgetId: "${widgetUuid}", // Unique widget ID
    pacId: "${pacUuid}", // Configuration ID
    publicApiKey: "${publicApiKey || '[INSERT_PUBLIC-API-KEY_HERE]'}", // Public API key to authenticate
    userId: {
      selector: '#${userIdSelector || '[INSERT_USEREMAIL-FIELD-ID_HERE]'}' // Capture user ID (email)
    },
    inputs: [
      {
        selector: '#${consentSelector || '[INSERT_CONSENT-FIELD-ID_HERE]'}', // Element capturing user preferences
        optionUuid: "${optionUuid}", // Option ID for preferences,
        validate: function () {
          // (optional) Return validation result to prevent submission
          return true;
        }
      }
    ]
  });
</script>`}
  >
    {'<script>'}
    <br />
    &nbsp;&nbsp;// Attach the preference management event listener
    <br />
    &nbsp;&nbsp;{'window.setupForm({'}
    <br />
    &nbsp;&nbsp;&nbsp;&nbsp;{'event: {'}
    <br />
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;elementSelector: &quot;
    <strong>#{formSelector || '[INSERT_FORM-ID_HERE]'}</strong>
    &quot;,
    <br />
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;eventType: &quot;submit&quot;,
    <br />
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;observeDomChanges: false,
    <br />
    &nbsp;&nbsp;&nbsp;&nbsp;{'},'}
    <br />
    &nbsp;&nbsp;&nbsp;&nbsp;widgetId: &quot;
    <strong>{widgetUuid}</strong>
    &quot;,
    <br />
    &nbsp;&nbsp;&nbsp;&nbsp;pacId: &quot;
    <strong>{pacUuid}</strong>
    &quot;,
    <br />
    &nbsp;&nbsp;&nbsp;&nbsp;publicApiKey: &quot;
    <strong>{publicApiKey || '[INSERT_PUBLIC-API-KEY_HERE]'}</strong>
    &quot;,
    <br />
    &nbsp;&nbsp;&nbsp;&nbsp;{'userId: {'}
    <br />
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;selector: &quot;
    <strong>#{userIdSelector || '[INSERT_USEREMAIL-FIELD-ID_HERE]'}</strong>&quot;
    <br />
    &nbsp;&nbsp;&nbsp;&nbsp;{'}'}
    <br />
    &nbsp;&nbsp;&nbsp;&nbsp;inputs: [
    <br />
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'{'}
    <br />
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;selector: &quot;
    <strong>#{consentSelector || '[INSERT_CONSENT-FIELD-ID_HERE]'}</strong>&quot;,
    <br />
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'validate: function () {'}
    <br />
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;return true;
    <br />
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'},'}
    <br />
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;optionUuid: &quot;
    <strong>{optionUuid}</strong>
    &quot;,
    <br />
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'},'}
    <br />
    &nbsp;&nbsp;&nbsp;&nbsp;]
    <br />
    {'});'}
    <br />
    {'</script>'}
  </CodeSnippet>
);

export default GenerateDataCollectorScript;
