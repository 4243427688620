import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import PacService, { Pac } from 'lib/api/pacApi';
import { Form, Input, Tooltip, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import Logo from 'components/Logo';
import { showErrorResponseNotification } from 'lib/helpers';
import privateKeysService from 'lib/api/privateKeysApi';
import { OnboardingStorageService } from 'lib/services/onboardingStorageService';
import { ApiKeyType } from 'lib/enums/apiKey';
import { IOnboardingStep1 } from 'lib/interfaces/onboarding';
import Button from 'components/Button/Button';
import Spin from 'components/Spin';
import PreferenceCenter from 'assets/PreferenceCenter.svg';
import { StyledQuill } from 'pages/PAC/styled';

import { isNotEmptyField } from 'lib/regex/validations';
import {
  StyledLogo,
  Wrapper,
  Sidebar,
  TopButtons,
  StyledSwitch,
  StyledText,
  FlexWrapper,
  FormWrapper,
  ExampleTitle,
  ExampleContainer,
  ExampleImage,
  OnboardingContent,
  ScrollableContainer,
  OnboardingSidebarTitle,
} from './styled';
import { HeaderSection } from './HeaderSection';

const { Title } = Typography;

const Step1 = () => {
  const [form] = Form.useForm();
  const { user } = useAuth0();
  const [isDataLoading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  const data = OnboardingStorageService.getStep1Data();

  const checkPac = async () => {
    try {
      const response = await PacService.get({
        search: user?.email,
      });
      const pacsThatCustomerOwns = response.data.results;
      const pacLite = pacsThatCustomerOwns.find((el: Pac) => el.type === 'lite');

      if (pacLite) {
        try {
          const apiKeys = await privateKeysService.get();
          OnboardingStorageService.setOnboardingResults({
            widgetUuid: pacLite?.widgetUuids[0],
            pacUuid: pacLite.uuid,
            publicApiKey:
              apiKeys.data.apiKeys?.find((k) => k.type === ApiKeyType.PUBLIC)?.key || '',
            optionUuid: pacLite?.widgets[0]?.topics[0]?.options[0].uuid,
          });
          navigate('/onboarding/step-5');
        } catch (error) {
          showErrorResponseNotification(error);
        }
      }
    } catch (error) {
      showErrorResponseNotification(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    checkPac();
  }, []);

  const onFinish = (values: IOnboardingStep1) => {
    OnboardingStorageService.setStep1Data({
      ...values,
      logoUrl: values.logoUrl === '' ? undefined : values.logoUrl,
    });
    navigate('/onboarding/step-3');
  };

  return (
    <Wrapper>
      <Sidebar>
        <StyledLogo>
          <Logo />
        </StyledLogo>
        <div>
          <StyledText>Preference Manager Audience Unlocker</StyledText>
          <OnboardingSidebarTitle level={1}>Create your Preference Center</OnboardingSidebarTitle>
          <span>
            Get started by configuring the dedicated interface to allow your users to view and
            manage their privacy preference settings.
          </span>
        </div>
      </Sidebar>
      {isDataLoading ? (
        <OnboardingContent>
          <Spin />
        </OnboardingContent>
      ) : (
        <OnboardingContent>
          <HeaderSection activeStepIndex={1} />
          <Title level={2}>Configure</Title>
          <Title level={4} style={{ margin: 0, color: '#595959', fontWeight: 400 }}>
            Add your configuration details here to customize your Preference Center.
          </Title>
          <ScrollableContainer style={{ marginTop: '20px' }}>
            <Form
              form={form}
              layout="vertical"
              onFinish={onFinish}
              initialValues={
                data || {
                  title: '',
                  description: '',
                  widgetTitle: '',
                  widgetDescription: '',
                  logoUrl: '',
                  doubleOptIn: false,
                }
              }
            >
              <div>
                <FlexWrapper>
                  <ExampleContainer>
                    <ExampleTitle>Preference Center preview</ExampleTitle>
                    <ExampleImage>
                      <img style={{ width: '100%' }} src={PreferenceCenter} alt="Example" />
                    </ExampleImage>
                  </ExampleContainer>
                  <div style={{ maxWidth: '600px', flex: '600px' }}>
                    <FormWrapper>
                      <Form.Item
                        name="title"
                        label="Preference Center headline"
                        rules={[
                          { required: true, message: 'Please enter a title' },
                          isNotEmptyField,
                        ]}
                        data-cy="input-preferenceCenterTitle"
                      >
                        <Input placeholder="My Preference Center" />
                      </Form.Item>
                      <Form.Item
                        name="description"
                        label="Preference Center welcome message"
                        data-cy="input-preferenceCenterDescription"
                      >
                        <StyledQuill
                          modules={{
                            toolbar: [['bold', 'italic', 'underline'], ['link'], ['clean']],
                          }}
                          formats={['bold', 'italic', 'underline', 'strike', 'link']}
                          placeholder="Welcome to our Preference Center page..."
                          theme="snow"
                          style={{ fontSize: '14px' }}
                          data-cy="onboarding-pac-description-input"
                        />
                      </Form.Item>
                      <Form.Item
                        name="widgetTitle"
                        label="Consent section headline"
                        rules={[
                          { required: true, message: 'Please enter consent section headline' },
                          isNotEmptyField,
                        ]}
                        data-cy="input-consentTitle"
                      >
                        <Input placeholder="Choose your privacy settings" />
                      </Form.Item>

                      <Form.Item
                        name="widgetDescription"
                        label="Consent section description text"
                        data-cy="input-ConsentSectionCenterDescription"
                      >
                        <StyledQuill
                          modules={{
                            toolbar: [['bold', 'italic', 'underline'], ['link'], ['clean']],
                          }}
                          formats={['bold', 'italic', 'underline', 'strike', 'link']}
                          placeholder="Here you can manage your consents’ settings"
                          theme="snow"
                          style={{ fontSize: '14px' }}
                          data-cy="onboarding-widget-description-input"
                        />
                      </Form.Item>
                      <Form.Item
                        name="logoUrl"
                        label="URL to your Company logo"
                        data-cy="input-companyLogo"
                        rules={[
                          {
                            type: 'url',
                            message: 'This field must be a valid url.',
                          },
                        ]}
                      >
                        <Input placeholder="https://..." />
                      </Form.Item>

                      <Tooltip
                        title="You can activate the double opt-in scenario for your data, requiring users to confirm their data submissions via a confirmation email. If not confirmed, pending data will be deleted after 24 hours and will not be processed in connected marketing platforms."
                        placement="left"
                      >
                        <div style={{ display: 'flex', alignItems: 'baseline' }}>
                          <Form.Item
                            name="doubleOptIn"
                            valuePropName="checked"
                            data-cy="switch-doubleOptIn"
                          >
                            <StyledSwitch
                              checked={form.getFieldValue('doubleOptIn')}
                              onChange={(value) => form.setFieldValue('doubleOptIn', value)}
                              data-cy="configuration-new-widget-double-opt-in"
                            />
                          </Form.Item>
                          <span style={{ marginLeft: '10px' }}>
                            Activate Double-Opt-In (recommended)
                          </span>
                        </div>
                      </Tooltip>
                    </FormWrapper>
                  </div>
                </FlexWrapper>
              </div>
            </Form>
          </ScrollableContainer>
          <TopButtons style={{ justifyContent: 'right' }}>
            <Button
              type="black"
              size="large"
              htmlType="submit"
              data-cy="button-nextStep"
              onClick={form.submit}
            >
              Next
            </Button>
          </TopButtons>
        </OnboardingContent>
      )}
    </Wrapper>
  );
};

export default Step1;
