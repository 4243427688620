export const BuildHtmlToCopy = (
  formSelector: string,
  widgetUuid: string,
  pacUuid: string,
  publicApiKey: string,
  userIdSelector: string,
  consentSelector: string,
  optionUuid: string,
) => {
  return `
<script>
  // Attach the preference management event listener
  window.setupForm({
    event: {
      // The element selector that you want to listen for an event, can be a form, button, etc
      // Use CSS selectors like:
      // - .theElementClass
      // - #theElementId
      // - div.anything > form
      elementSelector: '#${formSelector || '[INSERT_FORM-ID_HERE]'}', // The form to capture preferences
      eventType: "submit", // The event type, usually form submission
      observeDomChanges: false,
    },
    widgetId: "${widgetUuid}", // Unique widget ID
    pacId: "${pacUuid}", // Configuration ID
    publicApiKey: "${publicApiKey || '[INSERT_PUBLIC-API-KEY_HERE]'}", // Public API key to authenticate
    userId: {
      selector: '#${userIdSelector || '[INSERT_USEREMAIL-FIELD-ID_HERE]'}' // Capture user ID (email)
    },
    inputs: [
      {
        selector: '#${consentSelector || '[INSERT_CONSENT-FIELD-ID_HERE]'}', // Element capturing user preferences
        optionUuid: "${optionUuid}", // Option ID for preferences,
        validate: function () {
          // (optional) Return validation result to prevent submission
          return true;
        }
      }
    ]
  });
</script>`;
};
