import { Switch, Layout, Typography } from 'antd';
import styled from 'styled-components';

const { Content } = Layout;
const { Title } = Typography;

export const StyledSwitch = styled(Switch)`
  background-color: ${(props) => props.theme.colors.silver};
  min-width: 40px;
  height: 20px;

  &.ant-switch-checked {
    background-color: ${(props) => props.theme.colors.primary};

    & .ant-switch-handle {
      left: calc(100% - 18px);
    }
  }

  & .ant-switch-handle {
    width: 16px;
    height: 16px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  height: 100vh;
  max-width: 1430px;
  margin: auto;

  @media (max-width: 1070px) {
    flex-direction: column;
  }
`;

export const StyledLogo = styled.div`
  position: absolute;
  top: 40px;
  left: 40px;
`;

export const Sidebar = styled.div`
  flex: 0 0 540px;
  background-color: ${(props) => props.theme.colors.lightBackgroundBlue};
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  position: relative;

  @media (max-width: 1290px) {
    flex: 0 0 440px;
  }

  @media (max-width: 1170px) {
    flex: 0 0 340px;
  }

  & span {
    font-size: 20px;
  }
`;

export const StyledText = styled.p`
  font-weight: bold;
  font-size: 15px;
  color: ${(props) => props.theme.colors.primary};
`;

export const FlexWrapper = styled.div`
  display: flex;
`;

export const FormWrapper = styled.div`
  background-color: #f0f2f5;
  padding: 20px;
  padding-bottom: 0;
  border: 1px solid ${(props) => props.theme.colors.lightBlue};
  border-left: 0;
  border-radius: 8px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  height: 100%;

  & .ant-input {
    border-radius: 4px;
    padding: 12px 10px;
  }

  & .ql-toolbar.ql-snow {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  & .ql-container.ql-snow {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;

export const TopButtons = styled.div`
  display: inline-flex;
  padding: 24px 32px;
  align-items: center;
  gap: 24px;

  border-radius: 8px;
  background: ${(props) => props.theme.colors.white};
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  justify-content: space-between;
`;

export const ConsentCheck = styled.div`
  width: 450px;
  margin-left: 20px;
  margin-top: 20px;
`;

export const InfoText = styled.div`
  margin-bottom: 20px;
  font-size: 14px;
  color: #555;

  & a {
    color: ${(props) => props.theme.colors.primary};
  }
`;

export const HeaderStepper = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

export const StepperContainer = styled.div`
  flex: 1;
`;

export const Stepper = styled.div`
  padding-left: 12px;
  padding-right: 12px;
  display: inline-flex;
  gap: 8px;
  align-items: center;
  background-color: ${(props) => props.theme.colors.darkGray};
  height: 32px;
  border-radius: 50px;
`;

export const Circle = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 12px;
  height: 12px;
  border-radius: 32px;
  background-color: ${(props) => props.theme.colors.gray};
`;

export const Step = styled.div<{ active?: boolean; processed?: boolean }>`
  display: flex;
  align-items: center;
  gap: 4px;

  ${(props) =>
    props.active &&
    `
    color: ${props.theme.colors.primary};
    ${Circle} {
      background-color: ${props.theme.colors.primary};
    }
  `}

  ${(props) =>
    props.processed &&
    `
    ${Circle} {
      background-color: ${props.theme.colors.green};
    }
  `}
`;

export const IntroPoints = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  align-self: stretch;
  margin-top: 24px;
  position: relative;
  z-index: 1;
`;

export const IntroContent = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 28px;
  max-width: 694px;
  position: relative;
`;

export const ImageContainer = styled.div`
  padding: 25px;
  background-color: ${(props) => props.theme.colors.lightBackgroundBlue};
`;

export const PointNumber = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  padding: 7.8px 0px 8.2px 0px;
  justify-content: center;
  align-items: center;

  border-radius: 60px;
  border: 2.4px solid ${(props) => props.theme.colors.grayBlue100};
  color: ${(props) => props.theme.colors.white};
  background-color: ${(props) => props.theme.colors.grayBlue300};
`;

export const DashedLine = styled.div`
  width: 1px;
  height: 402px;
  position: absolute;
  border-right: 1px dashed ${(props) => props.theme.colors.defaultIcons};
  top: 22px;
  left: 12px;
  z-index: 0;
`;

export const TermsAndConditionContainer = styled.div`
  display: inline-flex;
  padding: 24px 32px;
  justify-content: flex-end;
  align-items: center;
  gap: 40px;
  border-radius: 8px;
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
`;

export const TermsAndConditionMessageContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;
  flex: 1 0 0;
`;

export const ExampleTitle = styled.div`
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border: 1px solid ${(props) => props.theme.colors.lightBlue};
  border-radius: 8px;
  border-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
`;

export const ExampleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  border-radius: 8px;
  min-width: 412px;

  /* Adjust flex size based on screen width */
  @media (max-width: 1190px) {
    min-max-width: 312px;
  }
`;

export const OnboardingSidebarTitle = styled(Title)`
  font-size: ${(props) => {
    return props.level === 1 && '52px!important';
  }};
`;

export const ExampleImage = styled.div`
  background-color: ${(props) => props.theme.colors.lightBackgroundBlue};
  border: 1px solid ${(props) => props.theme.colors.lightBlue};
  display: flex;
  justify-content: center;
  border-bottom-left-radius: 8px;
  flex: 1;
`;

export const OnboardingContent = styled(Content)`
  padding: 40px;
  background: ${(props) => props.theme.colors.white};
  display: flex;
  flex-direction: column;

  & .ant-spin {
    min-width: 100%;
  }

  & .ant-form-item-required {
    &:after {
      display: inline-block !important;
      content: '*' !important;
      margin-right: 0;
      color: ${(props) => props.theme.colors.red};
    }

    &:before {
      content: '' !important;
    }
  }
`;

export const GoogleUserDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const GoogleCustomerAdsContainer = styled.div`
  display: flex;
  width: 544px;
  padding: 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;

  margin-top: 32px;

  border-radius: 8px;
  background-color: ${(props) => props.theme.colors.darkGray};
`;

export const LoggedInAsContainer = styled.div`
  display: flex;
  padding: 10px 12px;
  align-items: center;
  gap: 16px;
  color: ${(props) => props.theme.colors.greyIcons};

  & ${Circle} {
    width: 18px;
    height: 18px;
    background-color: ${(props) => props.theme.colors.green};
  }
`;

export const SubTitle = styled.p`
  color: ${(props) => props.theme.colors.silver};
`;

export const SnippetContainer = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
`;

export const CodePlacement = styled.div`
  background-color: ${(props) => props.theme.colors.lightBlue200};
  padding: 12px;
  border-radius: 6px;
`;

export const CodeToCopy = styled.div`
  border-radius: 6px;
  padding: 12px;
  border: 1px solid ${(props) => props.theme.colors.blue500};
  background-color: ${(props) => props.theme.colors.white};

  & code {
    font-size: 12px;
  }
`;

export const LineDivider = styled.div`
  margin-top: 32px;
  margin-bottom: 32px;
  height: 1px;
  background-color: ${(props) => props.theme.colors.gray};
`;

export const ExternalLinks = styled.div`
  display: flex;
  padding: 12px 16px 12px 12px;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-radius: 4px;
  background-color: ${(props) => props.theme.colors.darkGray};

  & a {
    text-decoration: underline;
    color: ${(props) => props.theme.colors.primaryIcons};
    &.full-with {
      width: 100%;
    }
  }
`;

export const ScrollableContainer = styled.div`
  flex: 1;
  overflow-x: auto;
  padding-bottom: 40px;
  display: flex;

  & .ant-form-item {
    margin-bottom: 20px;
  }
`;
