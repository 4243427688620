import React, { useEffect, useState } from 'react';
import { Form, Spin } from 'antd';

import { IWidget } from 'lib/interfaces/widget';
import useMessage from 'lib/hooks/useMessage';
import { Description, Title } from 'shared-components/ModalsContent';
import { showErrorResponseNotification } from 'lib/helpers';
import GCMIntegrationApiService from 'lib/api/googleCustomerMatchIntegrationApi';

import Modal from 'components/Modal/Modal';
import { StyledSelect as Select } from 'components/Select/Select';
import { Item, Input } from 'components/Form';

import { IGCMConfig, IGcmDetailsData } from 'lib/interfaces/googleCustomerMatch';

import { Flex } from 'shared-components';
import { Label, SelectsWrapper } from '../CreateGCMIntegration/styled';

interface IProps {
  onClose: () => void;
  widgetsList: Array<IWidget>;
  gcmConfig: IGCMConfig;
}

const EditGCMModal = ({ onClose, gcmConfig, widgetsList }: IProps) => {
  const message = useMessage();
  const [gcmForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [disableSave, setDisableSave] = useState(false);

  const [gcmDetailsData, setGcmDetailsData] = useState<Array<IGcmDetailsData>>();
  const [selectedAdsAccount, setSelectedAdsAccount] = useState<IGcmDetailsData>();

  const onFieldsChange = () => {
    const hasErrors = gcmForm.getFieldsError().some(({ errors }) => errors.length);

    setDisableSave(hasErrors);
  };

  useEffect(() => {
    const init = async () => {
      setLoading(true);
      try {
        const { data } = await GCMIntegrationApiService.getCustomerDataByGcmConfigUuid(
          gcmConfig.uuid,
        );

        setGcmDetailsData(data);
        setSelectedAdsAccount(data?.find((d: any) => d.id === gcmConfig.customerId));
        setLoading(false);
      } catch (error) {
        showErrorResponseNotification(error);
      }
    };

    init();
  }, [gcmConfig]);

  const onPrimaryClick = async () => {
    setLoading(true);
    try {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      await GCMIntegrationApiService.editConfig(gcmConfig.uuid!, {
        title: gcmForm.getFieldValue('title'),
        customerId: gcmForm.getFieldValue('customerId'),
        customerListId: gcmForm.getFieldValue('customerListId'),
      });
      message.success('GCM Configuration saved successfully!');
      onClose();
    } catch (error) {
      showErrorResponseNotification(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      visible
      title="Edit Integration Configuration"
      secondaryButton={{
        label: 'Cancel',
        onClick: () => onClose(),
      }}
      primaryButton={{
        label: 'Save',
        onClick: onPrimaryClick,
        disabled: disableSave,
        loading,
      }}
      width={770}
      bodyHeight={500}
      onClose={onClose}
      maskClosable={false}
    >
      <Title data-cy="gcm-edit-modal">Update Integration Settings</Title>
      <Description>Here you can adjust Google Customer Match integration settings</Description>
      <Form
        name="gcm-settings"
        form={gcmForm}
        initialValues={gcmConfig}
        style={{ padding: '16px' }}
        onFieldsChange={onFieldsChange}
      >
        <Item
          label="Title"
          name="title"
          required
          rules={[{ required: true, message: 'Please enter a title for the integration.' }]}
        >
          <Input data-cy="gcm-title" placeholder="Integration Title" />
        </Item>
        <Label>Select consent source:</Label>
        <SelectsWrapper>
          <Form.Item>
            <Select
              disabled
              placeholder="Select Widget"
              defaultValue={
                widgetsList.find((el) => el.uuid === gcmConfig.consentSignalsSource.widgetUuid)
                  ?.title
              }
            />
          </Form.Item>
          <Form.Item>
            <Select
              disabled
              placeholder="Select Topic"
              value={
                widgetsList
                  .find((el) => el.uuid === gcmConfig.consentSignalsSource.widgetUuid)
                  ?.topics.find((topic) => topic.uuid === gcmConfig.consentSignalsSource.topicUuid)
                  ?.name
              }
            />
          </Form.Item>
          <Form.Item>
            <Select
              placeholder="Select Consent Source"
              disabled
              value={
                widgetsList
                  .find((el) => el.uuid === gcmConfig.consentSignalsSource.widgetUuid)
                  ?.topics.find((topic) => topic.uuid === gcmConfig.consentSignalsSource.topicUuid)
                  ?.options.find(
                    (option) =>
                      option.uuid === gcmConfig.consentSignalsSource.optionUuidsFor.userData &&
                      option.uuid === gcmConfig.consentSignalsSource.optionUuidsFor.personalization,
                  )?.name
              }
            />
          </Form.Item>
        </SelectsWrapper>
        <div style={{ textAlign: 'center' }}>
          <p>
            Google account: <span style={{ fontWeight: 'bold' }}>{gcmConfig.email}</span>
          </p>
        </div>
        {loading && (
          <Flex alignItems="center" justifyContent="center">
            <Spin />
          </Flex>
        )}
        {!loading && (
          <>
            <Item
              name="customerId"
              required
              rules={[{ required: true, message: 'Please select your Google Ads Customer ID.' }]}
            >
              <Select
                placeholder="Google Ads Account (CustomerID)"
                onChange={(value) => {
                  gcmForm.setFieldValue('customerListId', null);
                  setSelectedAdsAccount(gcmDetailsData?.find((d) => d.id === value));
                }}
              >
                {gcmDetailsData?.map((details) => (
                  <Select.Option key={details.id} value={details.id}>
                    {details.name}
                  </Select.Option>
                ))}
              </Select>
            </Item>
            <Item
              name="customerListId"
              required
              rules={[{ required: true, message: 'Please select your Customer List ID.' }]}
            >
              <Select placeholder="Customer List ID">
                {selectedAdsAccount?.customerList?.map((details) => (
                  <Select.Option key={details.id} value={details.id.toString()}>
                    {details.name} ({details.id})
                  </Select.Option>
                ))}
              </Select>
            </Item>
          </>
        )}
      </Form>
    </Modal>
  );
};

export default EditGCMModal;
